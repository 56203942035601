import { useMediaQuery } from '@chakra-ui/react';

/**
 * @function useIsDesktopView
 * Custom hook that return current view is desktop view or not
 */
function useIsDesktopView() {
  const [isDesktopView] = useMediaQuery('(min-width: 1024px)');

  return { isDesktopView };
}

export default useIsDesktopView;