import canUseDOM from '@invitato/helpers/dist/canUseDOM';

/**
 * Function to set value to localstorage
 * @param {string} KEY
 * @param {string} value
 * @returns {boolean}
 */
function setItem(KEY, value) {
  try {
    if (canUseDOM) {
      localStorage.setItem(KEY, value);
      return true;
    }

    return false;
  } catch {
    return false;
  }
}

/**
 * Function to get value to localstorage
 * @param {string} KEY
 * @returns {any | undefined}
 */
function getItem(KEY) {
  try {
    if (canUseDOM) {
      const result = localStorage.getItem(KEY);
      return result;
    }

    return undefined;
  } catch {
    return undefined;
  }
}

/**
 * Function to remove item in localstorage
 * @param {string} KEY
 * @returns {boolean}
 */
function deleteItem(KEY) {
  try {
    if (canUseDOM) {
      localStorage.removeItem(KEY);
      return true;
    }

    return false;
  } catch {
    return false;
  }
}

export { setItem, getItem, deleteItem };
