import { ENABLE_ANNOUNCEMENT_MODE } from '../constants/feature-flags';
import useQueryString from './useQueryString';

/**
 * Custom hook to return GuestName
 * @return {string}
 */
function useGroupLink() {
  if (!ENABLE_ANNOUNCEMENT_MODE) return true;

  const { getQueryValue } = useQueryString();
  const query = getQueryValue('m');

  
  return query === 'g';
}

export default useGroupLink;
